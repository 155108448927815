<template>
    <v-stepper v-model="e1" style="width: 100%;">
        <v-stepper-header>
            <v-stepper-step :complete="e1 >= 1" step="1">Received</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 >= 2" step="2">In Progress</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 >= 3" step="3">Delivered</v-stepper-step>
        </v-stepper-header>
    </v-stepper>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            e1: this.getStatusStep(this.item.status)
        };
    },
    methods: {
        getStatusStep(status) {
            switch (status) {
                case 'Received':
                    return 1;
                case 'In Progress':
                    return 2;
                case 'Delivered':
                    return 3;
                default:
                    return 1;
            }
        }
    }
};
</script>